@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,100..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

.ql-align-center {
    text-align: center;
}

.prose h2 {
    font-size: 2rem;
    font-weight: bold;
}

.mentions {
    font-family: 'DM Sans', sans-serif;
}

.mentions h2 {
    font-size: 1.875rem;
    font-weight: bold;
    text-transform: uppercase;
}

.mentions a {
    color: #0B86C8;
}

.mentions a:hover {
    color: #0a8f94;
    text-decoration: underline;
}

.mentions h3 {
    font-size: 1.25rem;
    font-weight: bold;
}

.mentions p {
    font-size: 1rem;
    font-weight: normal;
}

@keyframes ticker {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-50%);
    }
}

.animate-ticker {
    animation: ticker 30s linear infinite;
}

.animate-ticker:hover {
    animation-play-state: paused;
}

.polygon-white-bg {
    clip-path: polygon(0 0, 100% 0%, 90% 100%, 0% 100%);
}

@media (max-width: 640px) {
    .polygon-white-bg {
        clip-path: polygon(0 0, 100% 0%, 95% 100%, 0% 100%);
    }
}

.background-hero {
    background-color: #0B86C8;
}

@media (min-width: 1024px) {
    .background-hero {
        clip-path: polygon(0 0, 80% 0, 82% 100%, 0% 100%);
        background-color: #0B86C8;
    }
}

@media (min-width: 1024px) {
    .background-hero-image {
        clip-path: polygon(0% 0, 100% 0, 100% 100%, 8% 100%);
    }
}

.slick-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(11, 188, 193);
    border-radius: 50%;
    width: 50px;
    height: 50px;
    cursor: pointer;
    z-index: 1000;
}

.slick-next {
    right: 20px;
}

.slick-prev {
    left: 20px;
}

@media (max-width: 768px) {
    .slick-prev, .slick-next {
        width: 40px !important;
        height: 40px !important;
    }

    .slick-next {
        right: 10px !important;
    }

    .slick-prev {
        left: 10px !important;
    }
}

@media (max-width: 480px) {
    .slick-prev, .slick-next {
        width: 30px !important;
        height: 30px !important;
    }

    .slick-next {
        right: 5px !important;
    }

    .slick-prev {
        left: 5px !important;
    }
}

@media (min-width: 1280px) {
    .detailPageSlider .slick-slide {
        width: 350px !important;
    }
}

@media (max-width: 1024px) {
    .detailPageSlider .slick-slide {  
        width: 350px !important;
    }
}

@media (max-width: 768px) {
    .detailPageSlider .slick-slide {  
        width: 300px !important;
    }
}

@media (max-width: 480px) {
    .detailPageSlider .slick-slide {    
        width: 250px !important;
    }
}

@media (max-width: 320px) {
    .detailPageSlider .slick-slide {      
        width: 200px !important;
    }
}

@media (max-width: 280px) {
    .detailPageSlider .slick-slide {        
        width: 150px !important;
    }
}
